<template>
    <div v-if="config.show" class="header_com">
        <div class="header_clone" :style="`background: ${config.backgroundColor || '#fff'}`">
            <div class="header_left" @click="goback">
                <van-icon class="header_icon" :color="`${config.iconColor || '#000'}`" name="arrow-left" />
            </div>

            <div :style="`color: ${config.titleColor || '#000'}`">{{ config.title }}</div>

            <!-- 文字 -->
            <div v-if="config.rightText" @click="onRightChange" class="header_right"
                :style="`color: ${config.rightColor || '#000'}`">
                {{ config.rightText }}
            </div>
            <!-- 客服跳转 -->
            <div v-if="config.showKeufu" @click="onKefuChange" class="header_kefu">
                <img class="kefu_icon" src="../assets/userPhone1.png" alt="">
                <span>客服</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        config: {
            type: Object,
            default: () => ({})
        },
    },
    data() {
        return {

        }
    },
    methods: {
        // 页面回退
        goback() {
            this.$router.go(-1)
        },
        onRightChange() {
            this.$emit('onRight')
        },
        onKefuChange() {
            this.$router.push("../lador/service")
        }
    }
}
</script>
<style scoped>
.header_com {
    height: 92px;
}

.header_icon {
    font-size: 40px;
    vertical-align: -8px;
}

.header_left {
    width: 60px;
    position: absolute;
    left: 35px;
    top: 0px;
}

.header_right {
    line-height: 92px;
    text-align: right;
    font-size: 30px;
    position: absolute;
    right: 35px;
    top: 0px;
}

.header_kefu {
    text-align: right;
    position: absolute;
    right: 35px;
    top: 0px;
    font-size: 26px;
    line-height: 92px;
}
.kefu_icon{
    width: 30px;
    vertical-align: -5px;
    margin-right: 5px;
}

.header_clone {
    width: 100%;
    height: 92px;
    line-height: 92px;
    display: flex;
    justify-content: center;
    font-size: 30px;
    vertical-align: middle;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
}
</style>
